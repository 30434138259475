import startAnalytics from '@fiverr-private/gtm';

/**
 * Call startAnalytics on load
 */
window.addEventListener(
    'load',
    () => {
        startAnalytics();
    },
    { once: true }
);
